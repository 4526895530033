
            import {Workbox} from 'workbox-window';
            import {registerRoute} from 'workbox-routing';
            import {NetworkOnly} from 'workbox-strategies';

            registerRoute(({url}) => url.pathname.startsWith('/scorm/'), new NetworkOnly());
            registerRoute(({url}) => url.pathname.startsWith('/scorm-az/'), new NetworkOnly());

            if ('serviceWorker' in navigator) {
                const wb = new Workbox('/service-worker.js');
                let registration;

                const showSkipWaitingPrompt = async (event) => {
                    const app = document.getElementById('app');
                    const updateAccepted = app.promptForUpdate(() => {
                        console.log('skipWaiting');
                        wb.messageSkipWaiting();

                        // Assuming the user accepted the update, set up a listener
                        // that will reload the page as soon as the previously waiting
                        // service worker has taken control.
                        wb.addEventListener('controlling', () => {
                            // At this point, reloading will ensure that the current
                            // tab is loaded under the control of the new service worker.
                            // Depending on your web app, you may want to auto-save or
                            // persist transient state before triggering the reload.
                            console.log('SW workbox controlling event, reloading');
                            window.location.reload();
                        });
                    });
                };

                // Add an event listener to detect when the registered
                // service worker has installed but is waiting to activate.
                wb.addEventListener('waiting', async (event) => {
                    // When `event.wasWaitingBeforeRegister` is true, a previously
                    // updated service worker is still waiting.
                    // You may want to customize the UI prompt accordingly.
                    if (await wb.controlling.wasWaitingBeforeRegister) {
                        console.log('SW workbox waiting');
                        showSkipWaitingPrompt(event);
                    }
                });

                wb.register();
            }
        